
.el-icon-warning-outline {
  margin-left: 2px;
  &.is-transaction-filter {
    color: #BDC2CB;
  }
}

.fraud_tooltip.el-tooltip__popper.is-dark {
  text-align: center;
  background: #4D555E;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.01em;

  &.tooltip-small {
    max-width: 200px;
  }
  &.tooltip-medium {
    max-width: 230px;
  }

  .popper__arrow,
  .popper__arrow::after{
    border-bottom-color: #4D555E !important;
  }
}
