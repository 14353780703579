<template>
  <div class="details-info-table">
    <h1 class="title">{{ title }}</h1>
    <el-table ref="dataTable" :data="detailsInfo" :row-class-name="setClassName" @row-click="onClickRow">
      <el-table-column prop="label" class-name="label" width="156px"> </el-table-column>
      <el-table-column prop="value" class-name="value">
        <template slot-scope="scope">
          <span :class="[scope.row.className]">
            {{ scope.row.value }}
          </span>
        </template>
      </el-table-column>
      <el-table-column type="expand" class-name="expand-column">
        <template slot-scope="scope">
          <p v-html="scope.row.details"></p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { IDomeTransactionalInfoTable } from '@/dome/types/IDomeTransactionalInfo'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },
    detailsInfo: {
      type: Array as PropType<Array<IDomeTransactionalInfoTable>>,
      required: true
    }
  },
  methods: {
    setClassName: function ({ row }: { row: IDomeTransactionalInfoTable }) {
      return row.details ? '' : 'no-expand'
    },
    onClickRow: function (row: any) {
      if (row.label === `${this.$t('dome.fraud.detail')}` && row.details.length) {
        ;(this.$refs.dataTable as any).toggleRowExpansion(row)
      }
    }
  }
})
</script>

<style scoped>
.details-info-table {
  margin-bottom: 44px;
}
.details-info-table >>> .no-expand .el-table__expand-column .cell {
  display: none;
}
.details-info-table >>> .el-table__header-wrapper {
  display: none;
}
.title {
  margin-bottom: 11px;
  color: #1b1b1c;
  font-size: 16px;
  font-weight: 700;
}
.details-info-table >>> .el-table--enable-row-hover .el-table__body tr.no-expand:hover > td {
  background-color: white;
}
.details-info-table >>> .el-table__row.expanded td {
  border-bottom: none;
}
.details-info-table >>> .el-table__row .cell {
  padding-left: unset;
  vertical-align: middle;
  height: 45px;
  line-height: 45px;
}
.details-info-table >>> .el-table__row .cell span {
  line-height: 45px;
}
.details-info-table
  >>> .el-table__row
  .el-table__expand-column
  .cell
  .el-table__expand-icon--expanded
  .el-icon-arrow-right {
  transform: rotate(180deg);
}
.details-info-table >>> .el-table__row .el-table__expand-column .cell {
  position: absolute;
  right: 0;
  top: 9px;
  width: 50px;
}
.details-info-table >>> .el-table__row .el-table__expand-column .cell .el-icon-arrow-right {
  transform: rotate(90deg);
  font-size: 16px;
}
.details-info-table >>> .el-table__row:not(.no-expand):hover {
  cursor: pointer;
}
.details-info-table >>> .label {
  font-size: 13px;
  font-weight: 600;
}
.details-info-table >>> .value {
  font-size: 13px;
  font-weight: 500;
}
.details-info-table >>> td {
  padding: unset;
  height: 45px !important;
}
.details-info-table >>> .el-table {
  border-top: 1px solid #1b1b1c;
}
.details-info-table >>> .el-table__expanded-cell {
  padding-left: unset;
  padding-bottom: 14px;
  font-size: 13px;
}
.details-info-table >>> .amount {
  font-weight: 800;
  font-size: 14px;
  color: #477cf4;
}
</style>
