
.el-button {
  width: 70px;
  height: 34px;
  margin: 0 5px;
  padding: 10px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  background: #477cf4;
  border: 1px solid #477cf4;
  color: #ffffff;

  &:hover {
    background: #326df5;
  }

  &.el-button--medium {
    width: 95px;
    height: 34px;
    padding: 8px 0;
    .transaction-button-label {
      white-space: normal;
      word-break: break-word;
    }
  }
}

.el-button.is-plain {
  background: #ffffff;
  border: 1px solid #d4d7dc;

  &:hover {
    background: #f6f9ff;
  }
}

.is-plain.transaction_button {
  border-color: #e3e3e3;

  &-default {
    color: #9297a0;
  }
  &-none {
    color: #9297a0;
  }
  &-normal {
    color: #2e9d97;
  }
  &-warning {
    color: #f2911e;
  }
  &-danger {
    color: #e96060;
  }
  &-watching {
    color: #7739fc;
    .transaction-button-label {
      font-size: 11px;
      margin-top: -2px;
    }
  }
}
