
.filter_checkbox {
  font-weight: 600;
  font-size: 14px;
  
  .el-checkbox__input .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-color: #d4d7dc;
  }
  
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: #477CF4;
    background: #477CF4;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #477CF4;
  }
  .el-checkbox__inner:hover {
    border-color: #477CF4;
  }

  .el-checkbox__inner::after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border-width: 2px;
    border-color: #FFFFFF;
  }
}
