
.icon_content {
  margin-right: 4px;
}

.el-icon-success {
  color: #2E9D97;
}

.el-icon-error {
  color: #E96060;
}
