
.table-transactions {
  border-top-color: #000000;

  thead {
    background: #fcfcfd;
    color: #4d555e;
  }

  td {
    border-bottom: 1px solid #ececec;
  }

  .transaction_column_tid .cell {
    padding-left: 20px;
  }
  .transaction_column_left .cell {
    padding-left: 25px;
  }

  .column_amount {
    font-weight: 700;
  }

  .cell {
    font-size: 13px;
    color: #1b1b1c;
    white-space: nowrap;
    p {
      white-space: normal;
      word-break: break-word;
    }
  }

  .column_status {
    border-left: 1px solid #ececec;
  }

  .el-table__empty-text {
    padding: 257px 0;
  }
}
