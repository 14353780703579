
.el-button {
  height: 70px;
  padding: 0 44px;
  margin: 0 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  background: #477CF4;
  border: 1px solid #477CF4;
  color: #FFFFFF;
  
  &:hover {
    background: #326df5;
  }
}

.el-button.is-plain {
  background: #F2F6FE;
  border: 1px solid #5893FF;
  color: #477CF4;
  
  &:hover {
    background: #e4ecfa;
  }
}
