
.popover_filter {
  width: 375px;
  max-height: 800px;
  padding: 0;
  overflow-y: auto;

  .filter_subtitle {
    display: flex;
    justify-content: space-between;
    padding: 15px 32px 15px 25px;
    height: 62px;
    min-height: 62px;
    align-items: center;
    border-bottom: 1px solid #ECECEC;

    .subtitle_text {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
