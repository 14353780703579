
.title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 15px;
  color: #4d555e;
}
.alert-graph {
  position: relative;
  margin-bottom: 11px;
  .background {
    height: 8px;
    background-color: #ececec;
    border-radius: 118px;
  }
  .value-area {
    position: absolute;
    top: 0;
    display: inline-block;
    height: 8px;
    border-radius: 118px;
    z-index: 3000;
  }
}
.color-dot {
  margin-right: 5px;
  margin-bottom: 1px;
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.title-and-unit {
  margin-right: 10px;
  font-size: 13px;
  color: #4d555e;
}
.value-and-unit {
  font-weight: 600;
  font-size: 13px;
  color: #1b1b1c;
}
.max-and-unit {
  position: absolute;
  right: 38px;
  font-weight: 500;
  font-size: 13px;
  color: #4d555e;
}
