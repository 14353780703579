<template>
  <div class="filter_datepicker">
    <el-date-picker
      v-model="from"
      class="datepicker_picker"
      size="small"
      type="date"
      placeholder="From"
      :picker-options="pickerOptions"
      @change="(v) => $emit('onChangeFrom', v)"
    >
    </el-date-picker>
    <el-date-picker
      v-model="to"
      class="datepicker_picker"
      size="small"
      type="date"
      placeholder="To"
      :picker-options="pickerOptions"
      @change="(v) => $emit('onChangeTo', v)"
    >
    </el-date-picker>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'FilterDatePicker',
  data () {
    return {
      from: '',
      to: '',
      pickerOptions: {
        disabledDate (time: Date) {
          return time.getTime() > Date.now();
        },
      }
    }
  }
})
</script>

<style scoped>
.filter_datepicker {
  display: flex;
  justify-content: space-between;
  width: 280px;
  margin-top: 8px;
}
.datepicker_picker {
  width: 135px;
  height: 35px;
}
</style>