<template>
  <div class="fraud_table">
    <span v-html="label"></span>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'InfoColumnHeader',
  props: {
    label: {
      type: String,
      required: true
    }
  }
})
</script>

<style>
.fraud_table .table_column .el-icon-warning-outline {
  color: #c1c5cd;
}
</style>