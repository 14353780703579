
.filter_radio .el-radio-button__inner {
  display: inline-block;
  width: 70px;
  padding: 12px 0;
  font-size: 12px;
  white-space: normal;
  word-break: normal;
}

.filter_radio .radio_wrapper .el-radio {
  margin-right: 40px;
  font-weight: normal;
  letter-spacing: -0.4px;

  .el-radio__inner {
    border-radius: 20%;
    background: #ffffff;
  }
  .el-radio__inner::after {
    width: 8px;
    height: 8px;
    border-radius: 20%;
    background: #2281f3;
  }
}
.filter_radio .radio_wrapper.radio_wrapper-pair {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .el-radio {
    margin-right: 25px;
  }
}
.filter_radio .radio_wrapper.radio_wrapper-pair-small {
  .el-radio {
    margin-right: 75px;
  }
}
.el-radio-button.is-multiple-lines {
  .el-radio-button__inner {
    padding: 6px 0;
  }
}
