<template>
  <div class="filter_division">
    <FilterCheckbox
      :label="filterLabel"
      :initial-value="isChecked"
      @onChange="(value) => $emit('onChange', value)"
    />
    <FraudTooltip
      v-if="hasTooltip"
      :tooltip-content="tooltipContent"
      filter-color="is-transaction-filter"
      size="medium"
    />
    <div
      v-show="isChecked"
      class="division_content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import FilterCheckbox from '@/dome/components/filter/inputs/FilterCheckbox.vue'
import FraudTooltip from '@/dome/components/FraudTooltip.vue'

export default Vue.extend({
  name: 'FilterDivision',
  components: { FraudTooltip, FilterCheckbox },
  props: {
    filterLabel: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: false
    },
    hasTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipContent: {
      type: String,
      required: false,
      default: ''
    }
  }
})
</script>

<style scoped>
.filter_division {
  padding: 15px 25px;
  min-height: 46px;
  align-items: center;
  border-bottom: 1px solid #ECECEC;
}

.division_content {
  padding: 14px 10px 10px 10px;
  color: #1B1B1C;
}
</style>