
.filter_input.text_input {
  margin-top: 15px;
  
  p {
    margin: 0 0 6px 0;
    font-weight: 600;
    font-size: 12px;
  }
  
  .el-input__inner {
    background: #FCFCFD;
    border: 1px solid #EEEEEE;
  }
  
  &:first-child {
    margin-top: 0;
  }
}
