
.fraud_subdashboard {
  .subdashboard_title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .subdashboard_content {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #1b1b1c;
  }
}
