
.subdashboard_wrapper {
  display: flex;
  justify-content: space-between;
  height: 122px;
  margin: 16px 0 50px 0;

  .subdashboard_statics {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-right: 15px;
    padding: 38px 0;
    background: #FCFCFD;
    border: 1px solid #EEEEEE;
    border-radius: 6px;

    .static_divider {
      height: 100%;
      background: #EEEEEE;
    }
  }
}
