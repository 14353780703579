
.subdashboard_alert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 21px 30px;
  width: 253px;
  height: 122px;
  background: #FCFCFD;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.alert_content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content_title {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #4D555E;
  }
}
