
.filter_checkbox_group .el-checkbox-button__inner {
  width: 70px;
  font-size: 12px;
}

.filter_checkbox_group .checkbox_wrapper .el-checkbox {
  margin-right: 40px;
  margin-top: 10px;
  font-weight: normal;
  letter-spacing: -0.4px;

  .el-checkbox__inner {
    border-radius: 20%;
  }
  
  &:nth-child(1) {
    margin-top: 0;
  }
  &:nth-child(2) {
    margin-top: 0;
  }
}

.filter_checkbox_group .checkbox_wrapper.checkbox_wrapper-pair {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .el-checkbox {
    margin-right: 25px;
  }
}

.filter_checkbox_group .checkbox_wrapper.checkbox_wrapper-pair-small {
  .el-checkbox {
    margin-right: 75px;
  }
}

.filter_checkbox_group {
  .el-checkbox__input .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border-width: 1px;
    border-color: #d4d7dc;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: #477CF4;
    background: #477CF4;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #477CF4;
  }

  .el-checkbox__inner:hover {
    border-color: #477CF4;
  }

  .el-checkbox__inner::after {
    left: 4px;
    top: 2px;
    width: 4px;
    height: 6px;
    border-width: 2px;
    border-color: #FFFFFF;
  }
}
