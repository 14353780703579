
.column_change {
  font-size: 12px;
  color: #4D555E;
  text-decoration: underline;
}

.change_status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    display: block;
    margin-left: 10px;
    color: #1B1B1C;

    &:hover {
      font-weight: bold;
      color: #000000;
    }
  }
}
