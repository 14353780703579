
.filter_amount {
  .amount_input {
    display: inline-block;
    width: 100px;
  }

  .amount_input .el-input__inner {
    background: #FCFCFD;
    &:hover,
    &:focus {
      border-color: #DCDFE6;
    }
  }

  .amount_input.input_select {
    width: 80px;

    .el-input__inner {
      background: #F7F7F7;
    }
  }

  .amount_input.amount_input-first .el-input__inner {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .amount_input.amount_input-middle .el-input__inner {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .amount_input.amount_input-last .el-input__inner {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .input_separator {
    display: inline-block;
    height: 36px;
    line-height: 34px;
    background: #FCFCFD;
    border-top: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
  }

  .filter_amount_tip {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.3px;
    color: #4D555E;

    &.filter_amount_tip-first {
      margin-top: 6px;
    }
  }
}
