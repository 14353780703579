
.column_status_chip {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 11px;
  font-weight: 700;
  box-shadow: none;

  &-success {
    background: #F0FCF1;
    color: #2E9D97;
  }
  &-fail {
    background: #FFF5F6;
    color: #E96060;
  }
}
